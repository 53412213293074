import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { createModel } from '@app/api/model.api';

interface CreateModelModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

interface NewModelFormData {
  model: string;
}

export const CreateModelModal: React.FC<CreateModelModalProps> = ({ open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isInitial, setIsInitial] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    if (!isInitial && open) {
      setIsInitial(true);
    }
  }, [open]);

  const handleSubmit = (values: NewModelFormData) => {
    setLoading(true);

    createModel({ ...values })
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Create new category',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal title="Create New Model" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <BaseForm form={form} layout="vertical" name="createModelForm" onFinish={handleSubmit}>
        <BaseForm.Item name="model" label="Model" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
