import { httpApi } from '@app/api/http.api';

export interface GeneratorTableRow {
  id: number;
  prefix: string;
  count: number;
  redeemed: number;
  createdAt: Date;
}

export interface CouponTableRow {
  code: string;
  deviceId?: number;
  redeemedAt?: Date;
}

export interface GeneratorListRequest {
  page?: number;
  limit?: number;
}

export interface CouponListRequest {
  generatorId: number;
  page?: number;
  limit?: number;
}

export interface GeneratorData {
  prefix: string;
  count: number;
}

export const getLinkDownloadCoupons = (generatorId: number) => {
  return `${process.env.REACT_APP_BASE_URL}coupons/download?generatorId=${generatorId}`;
};

export const getGeneratorList = async (payload: GeneratorListRequest) => {
  return httpApi.get('/coupons/generators', { params: payload });
};

export const getCouponList = async (payload: CouponListRequest) => {
  return httpApi.get('/coupons/', { params: payload });
};

export const generateCoupons = (data: GeneratorData): Promise<undefined> =>
  httpApi.post<undefined>('/coupons', { ...data }).then(({ data }) => data);
