import React, { useEffect, useState, useCallback } from 'react';
import { Space } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useMounted } from '@app/hooks/useMounted';
import { Card } from '@app/components/common/Card/Card';
import { Pagination, PaginationOrder, PaginationSortBy } from '@app/api/category.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PlusOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { notificationController } from '@app/controllers/notificationController';
import { formatDate } from '@app/utils/utils';
import { deleteModel, getModelList, ModelTableRow } from '@app/api/model.api';
import { CreateModelModal } from '@app/components/model/CreateModelModal';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const ModelPage: React.FC = () => {
  const [tableData, setTableData] = useState<{
    data: ModelTableRow[];
    pagination: Pagination;
    loading: boolean;
    sort: {
      sortBy: PaginationSortBy;
      order: PaginationOrder;
    };
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    sort: {
      sortBy: 'title',
      order: 'asc',
    },
  });
  const { isMounted } = useMounted();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);

  const fetch = useCallback(
    (pagination: Pagination, sort: { sortBy: PaginationSortBy; order: PaginationOrder }) => {
      setTableData((tableData) => ({ ...tableData, sort: sort, loading: true }));
      getModelList({
        page: pagination.current,
        limit: pagination.pageSize,
      }).then((res) => {
        setTableData({
          data: res.data.data,
          pagination: { ...pagination, total: res.data.total },
          loading: false,
          sort: sort,
        });
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination, {
      sortBy: 'title',
      order: 'asc',
    });
  }, [fetch]);

  const handleDelete = (model: string) => {
    deleteModel({ model }).then(() => {
      notificationController.success({
        message: 'Remove model',
        description: 'Successfully',
      });
      fetch(tableData.pagination, tableData.sort);
    });
  };

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination, tableData.sort);
  };

  const openModelModal = () => {
    setIsCreateOpen(true);
  };
  const hideModelModal = () => {
    setIsCreateOpen(false);
  };

  const columns: ColumnsType<ModelTableRow> = [
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => formatDate(record.createdAt),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Are you sure to delete this model?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.model)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        padding="0 1.875rem"
        title="Model list"
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={openModelModal}>
            Create new
          </Button>
        }
      >
        <Table
          loading={tableData.loading}
          columns={columns}
          dataSource={tableData.data}
          pagination={{ ...tableData.pagination, style: { alignItems: 'center' } }}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          bordered
          rowKey={(record) => record.id}
        />
      </Card>
      <BaseButtonsForm.Provider
        onFormFinish={() => {
          hideModelModal();
        }}
      >
        <CreateModelModal
          open={isCreateOpen}
          onCancel={hideModelModal}
          onFinish={() => setTimeout(() => fetch(tableData.pagination, tableData.sort), 250)}
        />
      </BaseButtonsForm.Provider>
    </>
  );
};

export default ModelPage;
