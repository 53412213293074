import { httpApi } from '@app/api/http.api';

export interface ModelTableRow {
  model: string;
  createdAt: Date;
}

export interface ModelListRequest {
  page?: number;
  limit?: number;
}

export const getModelList = async (payload: ModelListRequest) => {
  return httpApi.get('/models', { params: payload });
};

export const createModel = async (payload: { model: string }) => {
  return httpApi.post('/models', { ...payload });
};

export const deleteModel = async (payload: { model: string }) => {
  return httpApi.delete(`/models/${payload.model}`);
};
